<template>
  <div class="container-max-width pb-5">
    <div class="container-franquicias">
      <!-- <div class="breadcum w-100">
                <p>Inicio > Franquicias > </p>
            </div> -->
      <div class="img-franquicias col-12 col-lg-6">
        <img
          src="@/assets/imgs/img_franquicia.jpg"
          class="w-100"
          alt="imagen franquicias"
        />
      </div>
      <div class="formulario-franquicia col-12 col-lg-6">
        <div class="titulo-franquicia">
          <h1>
            Únete al exclusivo mundo clover, conviértete en socio de la
            excepcionalidad
          </h1>
        </div>
        <p class="mb-4 pe-3 pe-lg-0">
          ¿Te gusta la moda o eres comerciante? La franquicia clover es una
          excelente oportunidad de negocio para ti. Maximiza tus ingresos con un
          modelo de negocio probado por más de 20 años con alto margen
          comercial. Déjanos tus datos para ponernos en contacto contigo.
        </p>
        <div class="content-formulario pe-3 pe-lg-0">
          <div class="mb-4 w-100 input-simple-clover">
            <input
              v-model="correo"
              autocomplete="email"
              class="payment-input w-100"
              type="email"
              placeholder="Correo"
              @blur="loseFocus('email')"
              @focus="campoCorreoRequerido = false"
            />
            <label
              :class="campoCorreoRequerido ? 'invalid' : ''"
              class="label-input"
              :style="correo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Correo</label
            >
            <span v-if="campoCorreoRequerido" class="inavalida-input">{{
              correo ? 'Correo invalido' : 'Campo requerido'
            }}</span>
          </div>
          <div class="col-12 col-lg-6 mb-4 pe-lg-2 input-simple-clover">
            <input
              v-model="nombre"
              autocomplete="given-name"
              class="payment-input w-100"
              type="given-name"
              placeholder="Nombres"
              @blur="loseFocus('nom')"
              @focus="campoNombreRequerido = false"
            />
            <span v-if="campoNombreRequerido" class="inavalida-input">{{
              nombre ? 'Nombre invalido' : 'Campo requerido'
            }}</span>
            <label
              :class="campoNombreRequerido ? 'invalid' : ''"
              class="label-input"
              :style="nombre ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Nombres</label
            >
          </div>
          <div class="col-12 col-lg-6 ps-lg-2 mb-4 input-simple-clover">
            <input
              v-model="tel"
              class="payment-input w-100"
              type="number"
              placeholder="Teléfono de contacto"
              @blur="loseFocus('tel')"
              @focus="campoTelRequerido = false"
            />
            <span v-if="campoTelRequerido" class="inavalida-input">{{
              tel ? 'Teléfono invalido' : 'Campo requerido'
            }}</span>
            <label
              :class="campoTelRequerido ? 'invalid' : ''"
              class="label-input"
              :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Teléfono de contacto</label
            >
          </div>
          <div class="col-12 col-lg-6 mb-4 pe-lg-2 input-simple-clover">
            <input
              v-model="pais"
              autocomplete="given-name"
              class="payment-input w-100"
              type="given-name"
              placeholder="País"
              @blur="loseFocus('pais')"
              @focus="campoPaisRequerido = false"
            />
            <span v-if="campoPaisRequerido" class="inavalida-input">{{
              pais ? 'País invalido' : 'Campo requerido'
            }}</span>
            <label
              :class="campoPaisRequerido ? 'invalid' : ''"
              class="label-input"
              :style="pais ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >País</label
            >
          </div>
          <div class="col-12 col-lg-6 ps-lg-2 mb-4 input-simple-clover">
            <input
              v-model="ciudad"
              class="payment-input w-100"
              type="text"
              placeholder="Ciudad"
              @blur="loseFocus('ciudad')"
              @focus="campoCiudadRequerido = false"
            />
            <span v-if="campoCiudadRequerido" class="inavalida-input">{{
              ciudad ? 'Ciudad invalido' : 'Campo requerido'
            }}</span>
            <label
              :class="campoCiudadRequerido ? 'invalid' : ''"
              class="label-input"
              :style="ciudad ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Ciudad</label
            >
          </div>
          <div class="col-12 col-xl-6 mb-4 pe-xl-2">
            <div
              class="d-flex align-items-center justify-content-center p-2"
              style="background-color: #f5f5f5"
            >
              <div class="container-input-checkbox mb-0 align-items-center">
                <input
                  id="input-checkbox-tel"
                  v-model="tyc"
                  class="input-checkbox"
                  type="checkbox"
                  checkmark="checkmark"
                />
                <span class="checkmark"></span>
                <label
                  for="input-checkbox-tel"
                  style="color: #818181; font-size: 13px"
                  >Acepto
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'HabeasData',
                      params: { politica: 'politica-de-privacidad' },
                    }"
                    style="color: #818181; font-size: 13px"
                    class="router-clover"
                    >política tratamiento datos personales</router-link
                  ></label
                >
              </div>
            </div>
            <span v-if="campoTycRequerido" class="text-error"
              >Acepta los terminos y condiciones</span
            >
          </div>
          <div class="col-12 col-xl-6 mb-4 ps-xl-2">
            <button class="bnt-primary w-100" @click="sendFormulario()">
              Enviar formulario
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-done-franquicia"
      body-bg-variant="rgba(0, 13, 80, .5)"
      centered
      hide-footer
      hide-header
    >
      <div class="body-modal-newslatter-done">
        <div class="d-flex justify-content-center mb-3">
          <svg
            id="Shopicons_Light_Service"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <path
              id="Trazado_5206"
              data-name="Trazado 5206"
              d="M0,0H48V48H0Z"
              fill="none"
            />
            <g id="Shopicon">
              <path
                id="Trazado_5207"
                data-name="Trazado 5207"
                d="M41.083,19a2.959,2.959,0,0,0-.727.1A17.024,17.024,0,0,0,24.083,7l-.042,0L24,7A17.026,17.026,0,0,0,7.733,19.076,2.964,2.964,0,0,0,7.083,19a3.009,3.009,0,0,0-3,3v4a3.009,3.009,0,0,0,3,3,2.995,2.995,0,0,0,1.579-.463,3.039,3.039,0,0,0,.834-.776,14.869,14.869,0,0,1-.414-2.195A15.27,15.27,0,0,1,9,24a15.025,15.025,0,0,1,.083-1.567A14.765,14.765,0,0,1,9.5,20.238,15.011,15.011,0,0,1,24,9l.042,0a15,15,0,1,1-3.674,29.535,3.028,3.028,0,0,0-.805.826A3.159,3.159,0,0,0,19.069,41a3.009,3.009,0,0,0,3,3h4.014a3.009,3.009,0,0,0,3-3,2.959,2.959,0,0,0-.1-.727A17.061,17.061,0,0,0,40.356,28.9a2.959,2.959,0,0,0,.727.1,3.009,3.009,0,0,0,3-3V22A3.009,3.009,0,0,0,41.083,19Z"
              />
              <circle
                id="Elipse_191"
                data-name="Elipse 191"
                cx="2"
                cy="2"
                r="2"
                transform="translate(18.083 15.997)"
              />
              <circle
                id="Elipse_192"
                data-name="Elipse 192"
                cx="2"
                cy="2"
                r="2"
                transform="translate(26.002 15.997)"
              />
              <path
                id="Trazado_5208"
                data-name="Trazado 5208"
                d="M31,24H29a4.917,4.917,0,0,1-9.834,0h-2A6.917,6.917,0,0,0,31,24Z"
              />
            </g>
          </svg>
        </div>
        <div
          class="d-flex flex-wrap justify-content-center text-center position-relative"
        >
          <div class="divider-modal-newlatter">
            <div class="circle-newslatter" />
          </div>
          <div class="box-newslatter">
            <p class="bold">¡Solicitud registrada!</p>
            <p class="fs-6">
              Pronto nos pondremos en contacto contigo para brindarte más
              información.
            </p>
            <button
              class="bnt-primary"
              @click="$bvModal.hide('modal-done-franquicia')"
            >
              ¡Entendido!
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      correo: '',
      campoCorreoRequerido: false,
      nombre: '',
      campoNombreRequerido: false,
      tel: '',
      campoTelRequerido: false,
      ciudad: '',
      campoCiudadRequerido: false,
      pais: '',
      campoPaisRequerido: false,
      telefono: '',
      campoTelefonoRequerido: false,
      tyc: false,
      campoTycRequerido: false,
      msgErrorSolicitud: '',
    }
  },
  computed: {
    /* eslint-disable */
    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validateNom() {
      return this.nombre.length > 2
    },
    validateTel() {
      return this.tel.length > 6 && this.tel.length < 11
    },
    validatePais() {
      return this.pais && this.pais.length > 2
    },
    validateCiudad() {
      return this.ciudad && this.ciudad.length > 2
    },
  },
  watch: {
    tyc() {
      if (this.tyc) {
        this.campoTycRequerido = false
      }
    },
  },
  methods: {
    loseFocus(input) {
      if (input == 'email') {
        this.campoCorreoRequerido = !this.validateEmail
      }
      if (input == 'nom') {
        this.campoNombreRequerido = !this.validateNom
      }
      if (input == 'tel') {
        this.campoTelRequerido = !this.validateTel
      }
      if (input == 'pais') {
        this.campoPaisRequerido = !this.validatePais
      }
      if (input == 'ciudad') {
        this.campoCiudadRequerido = !this.validateCiudad
      }
    },
    async sendFormulario() {
      this.msgErrorSolicitud = ''
      if (
        this.validateEmail &&
        this.validateNom &&
        this.validateTel &&
        this.validateCiudad &&
        this.validatePais &&
        this.tyc
      ) {
        this.$recaptchaLoaded().then(async () => {
          this.$recaptcha('login').then(async (token) => {
            if (token) {
              this.$store.state.loaderDerek = true
              let data = new FormData()
              data.append('correo', this.correo)
              data.append('nombres', this.nombre)
              data.append('pais', this.pais)
              data.append('ciudad', this.ciudad)
              data.append('telefono', this.tel)
              await this.axios({
                method: 'POST',
                headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                },
                url: '/contacto/franquicia/registro',
                data: data,
              })
                .then(() => {
                  this.$bvModal.show('modal-done-franquicia')
                  this.correo = ''
                  this.campoCorreoRequerido = false
                  this.nombre = ''
                  this.campoNombreRequerido = false
                  this.tel = ''
                  this.campoTelRequerido = false
                  this.ciudad = null
                  this.campoCiudadRequerido = false
                  this.cedula = ''
                  this.campoCedulaRequerido = false
                  this.telefono = ''
                  this.campoTelefonoRequerido = false
                  this.tyc = false
                  this.campoTycRequerido = false
                  this.msgErrorSolicitud = ''
                  this.$store.state.loaderDerek = false
                })
                .catch((error) => {
                  console.log(error)
                  if (error.response.status == 500) {
                    this.msgErrorSolicitud =
                      'Parece que algo salió mal, intentalo más tarde'
                  } else {
                    let message = ''
                    for (const property in error.response.data.errors) {
                      message =
                        message + error.response.data.errors[property][0] + ' '
                    }
                    this.msgErrorSolicitud = message
                  }
                  this.$store.state.loaderDerek = false
                })
            }
          })
        })
      } else {
        this.campoCorreoRequerido = !this.validateEmail
        this.campoNombreRequerido = !this.validateNom
        this.campoTelRequerido = !this.validateTel
        this.campoPaisRequerido = !this.validatePais
        this.campoCiudadRequerido = !this.validateCiudad
        this.campoTycRequerido = !this.tyc
      }
    },
  },
}
</script>
<style>
.container-franquicias {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 120px;
}
.img-franquicias {
  margin: auto;
  max-width: 424px;
}
.formulario-franquicia {
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  position: relative;
  padding-left: 1rem;
}
.titulo-franquicia {
  position: relative;
  width: 120%;
  left: -20%;
  background-color: #ebeeff;
  padding: 1rem 3rem;
  margin-bottom: 2rem;
}
.titulo-franquicia h1 {
  font-size: 30px;
}
.content-formulario {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .img-franquicias {
    max-height: 350px;
    overflow: hidden;
  }
  .titulo-franquicia {
    width: 100%;
    left: 0;
    top: -3rem;
    margin-bottom: 0;
  }
  .formulario-franquicia {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .container-franquicias {
    width: 100%;
  }
  .formulario-franquicia {
    width: 90%;
  }
  .img-franquicias {
    max-height: 350px;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
  }
  .img-franquicias img {
    width: 100%;
  }
  .titulo-franquicia {
    width: 105.6%;
    padding: 1rem;
  }
}
</style>
